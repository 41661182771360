import React from "react"
import Layout from "../components/layout";
import './../styles/contact.scss';
import { graphql } from "gatsby";
import Pattern from "./../images/svg/panel_long_1.svg";
import { getResource } from "../utils/getResource"

const Kontakt = ({ data, location, }) => {
  const contactData = data.allMarkdownRemark.nodes;

  return (
    <Layout location={location} headerStyle={'darkBg'} pageRefs={[]} sectionClass="m-main__content-kontakt">
      <div className="m-main__contact">
        <h1 className="m-main__contact-header">{getResource(contactData, 'kontakt').subtitle1}</h1>
        <div className="m-main__contact-pattern">
          <Pattern />
        </div>
        <div className="m-section__contact-container">
          <p className="m-section__contact-text" dangerouslySetInnerHTML={{ __html: getResource(contactData, 'kontakt').content}}></p>
        </div>
      </div>
    </Layout>
  )
}

export default Kontakt

export const pageQuery = graphql`
  query($language: String) {
    allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "contact-item" }, language: { eq: $language }}}) {
      nodes {
        frontmatter {
          title
          subtitle1
          content
        }
      }
    }
  }
`
